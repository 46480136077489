import React from "react";
import { Component } from "react";
import { withRouter, numberWithSpaces, API_KEY } from "./GlobalFunctions";
//import i18n from "i18next";
import "./mobile.css";
import Spinner from "./components/spinner";
import Notfound from "./Notfound";
import telegram from "../../assets/img/Telegram_logo.svg";

export class Receipt extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            isLoaded: false,
            data: null,
        };
      }

      componentDidMount() {
        this.getData();
        
    }

    
getData = () => {

        fetch('https://smartpay.tj/telebot/invoices/' + this.props.params.invoiceId, 
        { 
            method: "GET", 
            headers : {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
                'x-app-token': API_KEY(),
            }
        })
        
        .then((response) => {
          if(response.status === 200){
              this.setState({resstatus: response});
              return response.json();     
          }else {
              this.setState({ requestFailed: true })
              window.location.href = 'https://smartpay.tj/404'
          }
        })
        .then((responseJson) => {
            
            this.setState({ data : responseJson, isLoaded: true, isError: false });
            
        })
        .catch((err) => {
            this.setState({ isLoaded: true, isError: true });
        });
    }

    startPayment = () => {
    
      fetch("https://smartpay.tj/subapi/payler/getsession", 
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          'x-app-token': API_KEY(),
        },
  
        body: JSON.stringify({
          'amount': this.state.data.amount,
          'product': this.state.data.description,
          'lifetime': 21600,
          'invoice_id': this.state.data.invoice_number,
          'currency': 'TJS'
        })
  
      }
      
      )
      
      .then((response) => {
        if(response.status === 200){
            return response.json();     
        }else {
            this.setState({ requestFailed: true })
            window.location.href = 'https://smartpay.tj/404'
        }
      })
        .then((responseJson) => {
          
          window.location.href = 'https://sandbox.payler.com/gapi/Pay?session_id='+responseJson.id;
          
      });
    
  }
    
    render () {
      const { isLoaded, data, isError, resstatus } = this.state;
      const { translation } = this.props;
  
      if(isLoaded && (isError || resstatus.status !== 200)){
          return (<Notfound status={resstatus} />);
      };
  
      return (
          <div id="invoice" className="invoice">
          {!isLoaded 
          ?<Spinner />
          :<div className="icontent" >
              {window.document.getElementById("invoice").style.setProperty("align-items", "baseline")} 
              <div className="title">
                  <div className="mlogo">
                      <img alt='' style={{height:"82px", width:"82px"}} src={!data.merchant_logo ?telegram :data.merchant_logo}></img>
                  </div>
              </div>
              <>{data.merchant_name
              ?<div className="merchant">
                <div className="mtitle">{data.merchant_name}</div>
                <div className="addr">{data.merchant_address}</div>
              </div>
              :<></>

              }
              </>
              
              <div className="mreceipt-info">
                <div>
                  <div>{!(data.status === "success")
                          ? document.title = translation('INVOICE') + ' '+ translation('NUMBER') 
                          : document.title = translation('RECEIPT') + ' '+ translation('NUMBER')
                  }</div>
                  <div>
                      {data.invoice_number.substring(0,data.invoice_number.indexOf('-')).toUpperCase()}
                  </div>
                </div> 
                <div>
                  <div> {translation('DATE')}</div>
                  <div> {data.create_at.substring(0, data.create_at.indexOf('T'))}</div>
                </div>
                
                <div>
                  {data.merchant_tin 
                  ?<>
                    <div>{translation('TIN')}</div>
                    <div>{data.merchant_tin}</div>
                    </>
                  :<>
                    <div>{translation('PHONE')}</div>
                    <div>{numberWithSpaces(data.phonenumber)}</div>
                  </>}
                </div>
                  
              </div>
  
              <div className="head">
                  <p> {translation ('DESCRIPTION')} </p>
                  <p> {translation ('AMOUNT')} </p>
              </div>
              
              <div className="mreceipt-data">
              {!data.order_items
              ?<div className="mreceipt-data-row">
                <div style={{textAlign:"justify", paddingRight:"3rem"}}>{data.description} </div>
                <div>{numberWithSpaces((data.amount/100).toFixed(2))} </div>
              </div>
              :<>
                {data.order_items.map ((item, index) =>(
                  <div className="mreceipt-data-row">
                    <div style={{ textAlign: "justify", paddingRight: "3rem" }}>
                      {index+1}. {item.id} {item.name} x {item.qty} x {(item.price / 100).toFixed(2)} 
                    </div>
                    <div>{ numberWithSpaces(((item.qty * item.price) / 100).toFixed(2))} </div>
                  </div>
                ))}
              </>
              }
                
                  
              </div>
              <div className="head">
                <p> {translation('TOTAL_AMOUNT')} </p>
                <p> {numberWithSpaces((data.amount/100).toFixed(2))} {translation('CURRENCY_1')} </p>
              </div>
              {!(data.status === 'success')
                ?<div className="ibutton">
                  <a href='/#' className="pay_button" onClick={() => this.startPayment()}> 
                      <p >{translation('PAY')} {numberWithSpaces((data.amount/100).toFixed(2))} {translation('CURRENCY_1')}</p>
                  </a>
              
                </div>
                :<div className="ibox">
                  <div>{data.description}</div>
                  <div className="stamp_m"> {translation('PAYED_STAMP')}</div>
  
                </div>
           }
           </div>
          }
          </div>
          );
      
      }
  
  
  }

export default withRouter(Receipt) ;