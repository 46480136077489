import React, { useRef, useState } from "react";
import "./main.css";
import AuthCode from 'react-auth-code-input';
import { useTranslation } from "react-i18next";
import { Form, Button} from "antd";
import { useNavigate } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';

export function Qr() {
    const { t } = useTranslation();
    const AuthInputRef = useRef(null);
    const [valueInv, setValueInv] = useState('');
    const navigate = useNavigate();
    const InvNumOnChange = (res) => {
        setValueInv(res);
    };

    const handleInvNum = () => {
        navigate(`/invoice/${valueInv}`);
    };

    return (
        <div>
        <Form onFinish={handleInvNum} autoComplete="off">
        <div className="flex w-full sm:text-[18px] md:text-[28px] justify-center pt-4">{t("ENTER_INV_NUM")}</div>
        <div className="text-center w-full text-slate-500 sm:text-[11px] md:text-[14px] px-2">{t("SENDED_BY_SMS")}</div>
        <Form.Item className="flex w-full justify-center pt-4">
            <AuthCode 
                inputClassName="sm:w-[25px] sm:h-[40px] sm:text-[16px] sm:rounded-[5px] sm:mx-[1.5px] md:w-[35px] md:h-[45px] md:text-[24px] text-center md:mx-1 md:rounded-[8px] border-solid border-[1px] border-[#d6d6d6]"
                onChange={InvNumOnChange}
                ref={AuthInputRef}
                length={12}
                allowedCharacters="numeric" 
            />
        </Form.Item>
        <Form.Item className="flex w-full justify-center pt-4">
              <Button htmlType="submit" className="h-[40px] w-[150px] bg-emerald-600 rounded-[8px] text-white" key="verify">
                  {t("NEXT")}
              </Button>
            </Form.Item>  
        </Form>
        <div>
        <div className="text-center px-4 w-full text-slate-700 sm:text-[14px] md:text-[18px] justify-center py-4">{t("INSTRUCTION")}</div>
        <div className="text-justify py-2 px-4 w-full text-slate-500 sm:text-[12px] md:text-[16px] justify-center">{t("QR_1")}</div>
        <div className="text-justify py-2 px-4 w-full text-slate-500 sm:text-[12px] md:text-[16px] justify-center">{t("QR_2")}</div>
        <div className="text-justify py-2 px-4 w-full text-slate-500 sm:text-[12px] md:text-[16px] justify-center">{t("QR_3")}</div>
        {/*<QRCode
            value = { `https://smartpay.tj/qr` } 
                        fgColor = "#000"
                        eyeRadius = { 5 }
                        qrStyle = "dots"
                        //logoImage = "logo192.png"
                        //removeQrCodeBehindLogo = "true"
                
                    />*/}
        </div>
        </div> 
    
    );
}

export default Qr;