import React from "react";
import i18n from 'i18next';

import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Collapse } from 'antd'; 
import { isMobile } from "react-device-detect";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );


export class CsInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoaded: false,
        data: null,
        payler_session: null,
        key: 'smartpay',
        invoice_total: 0,
        requestFailed: false,
        pdfDoc: null,
        localFileurl: [{ invoice_number: null, blob_url: null },],
        processing: true,
    };
  }

  componentDidMount() {
    
    this.onShowFile();
    
  } 

  componentDidUpdate() {
    
  }

  

  onShowFile = () => {
        
    const m_json = this.state.localFileurl.find(
      (el) => el["invoice_number"] === this.props.params.invoiceId
    );
    if (m_json !== void 0) {
      this.setState({pdfDoc: m_json["blob_url"], });
      //document.title = t("PAYMENT_DOCUMENT") + " № " + this.props.params.invoiceId;
      return;
    }
    
    let url = `https://smartpay.tj/subapi/payler/reports/csf868_pdf?invoice_id=${this.props.params.invoiceId}`;
    this.setState({processing: true});
    fetch(url, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'x-app-token': API_KEY(),
        },
      }).then(async (response) => {
        const blob = await response.blob();
        const m_url = URL.createObjectURL(blob);
        this.setState({pdfDoc: m_url});
        this.setState({localFileurl: [...this.state.localFileurl, 
            {invoice_number: this.props.params.invoiceId, blob_url: m_url,},
        ]});
      });

    this.setState({ processing: false, });
    //document.title = t("PAYMENT_DOCUMENT") + " № " + row?.invoice_number;
    //setInvoice(row);

    //base64ToBlob( data, 'application/pdf' )));
  };


render () {
    const { isLoaded, processing, pdfDoc } = this.state;
    
return (
<div>
  <div id = "SubHeader" className="flex text-blue-50 bg-transparent"></div>
    <div id = "InvoiceContainer" style={{textAlign: "-webkit-center"}} className="block bg-white text-center">
      {processing ?
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div>
      :
          <div className="h-screen w-full overflow-y-auto">

           <embed
              title={this.props.params.invoiceId}
              src={`${pdfDoc}#toolbar=1`}
              className="w-full h-[100%]"
            />
          </div>
      }
          <div className="md:h-40"></div>
      </div>
      
      

      
    
      </div>
      );

  } 
  
}


export default withRouter(CsInvoice) ;