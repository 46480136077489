import React from "react";
import i18n from 'i18next';

import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Collapse, Timeline, Steps } from 'antd'; 
import { isMobile } from "react-device-detect";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );


export class MobileInvoiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoaded: false,
        data: null,
        payler_session: null,
        key: 'smartpay',
        invoice_total: 0,
        requestFailed: false,
        pdfDoc: null,
        localFileurl: [{ invoice_number: null, blob_url: null },],
        processing: false,
    };
  }

  componentDidMount() {
    
    this.getData();
    
  } 

  componentDidUpdate() {
    
  }

  getData = () => {
    
    fetch('https://smartpay.tj/subapi/payler/invoice/balance/' + this.props.params.invoiceId, 
    { 
        method: "GET", 
        headers : {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'x-app-token': API_KEY(),
        }
    })

    .then((response) => {
      if(response.status === 200){
          return response.json();     
      }else {
          this.setState({ requestFailed: true })
          window.location.href = 'https://smartpay.tj/'
      }
    })
        .then((responseJson) => {
          this.setState({ data : responseJson, isLoaded: true });
        });
  }

  onShowFile = () => {
        
    const m_json = this.state.localFileurl.find(
      (el) => el["invoice_number"] === this.props.params.invoiceId
    );
    if (m_json !== void 0) {
      this.setState({pdfDoc: m_json["blob_url"], });
      //document.title = t("PAYMENT_DOCUMENT") + " № " + this.props.params.invoiceId;
      return;
    }
    
    let url = `https://smartpay.tj/subapi/payler/reports/csf868_pdf?invoice_id=${this.props.params.invoiceId}`;
    this.setState({processing: true});
    fetch(url, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'x-app-token': API_KEY(),
        },
      }).then(async (response) => {
        const blob = await response.blob();
        const m_url = URL.createObjectURL(blob);
        
        let alink = document.createElement("a");
        alink.href = m_url;
        alink.download = `Ҳисобнома_фактура_${this.props.params.invoiceId}.pdf`;
        alink.click();
      });

    this.setState({ processing: false, });
    //document.title = t("PAYMENT_DOCUMENT") + " № " + row?.invoice_number;
    //setInvoice(row);

    //base64ToBlob( data, 'application/pdf' )));
  };

  

  startPayment = () => {
    //let total_amount = this.state.data.visa_amount;
    //if(!this.state.data.bank_fee_included){
    //  total_amount = (this.state.data.amount_due / (1-this.state.data.bank_fee)).toFixed(0);
    //}
    

    fetch("https://smartpay.tj/subapi/payler/payment_session", 
    {
      method: "POST",
      credentials:"include",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
        'x-app-token': API_KEY(),
      },
      


      body: JSON.stringify({
        'amount': this.state.data.invoice.visa_amount,
        'product': (this.state.data.invoice.subject[i18n.language] === null ? this.state.data.invoice.subject['tj'] : this.state.data.invoice.subject[i18n.language]),
        'lifetime': 43200,
        'invoice_number': this.state.data.invoice.key,
        'currency': 'TJS',
        'test_mode': this.state.data.invoice.test_mode,
      })

    }
    
    )
    
    .then((response) => response.json())
      .then((responseJson) => {
        
        window.location.href = responseJson.payment_url;
        
    });
  
  }

render () {
    const { isLoaded, data, pdfDoc } = this.state;
    const { translation } = this.props;
    const { Panel } = Collapse;

return (
<div>
  <div id = "SubHeader" className="flex text-blue-50 bg-transparent"></div>
    <div id = "InvoiceContainer" style={{textAlign: "-webkit-center"}} className="block bg-white text-center">
      {!isLoaded ?
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div>
        :
        <div className="sm:pt-0 md:pt-[2rem] sm:w-full md:w-[80%] lg:w-[50%]">
        <div className="flex w-full mt-4">
          <div className="flex w-[25%] ml-4 justify-center items-center">
            <img alt='' className="sm:!h-[86px]" src={data.merchant.logo}></img>
          </div>
          <div className="w-[75%] block  text-center">
            <div className="mx-2 sm:text-[14px] md:text-[18px] upper font-semibold">{data.merchant.name[i18n.language]}</div>
            {data.merchant.unit_name[i18n.language] ? 
            <div className="mx-2 text-center upper sm:text-[10px] md:text-[14px] font-semibold">{data.merchant.unit_name[i18n.language]}</div>
            :<></>
            }
            <div className="mx-2 sm:text-[11px] md:text-[13px] text-center py-[5px]" >
              {data.merchant.address} {`${translation('TEL')} ${data.merchant.phonenumber} ${translation('EMAIL')} ${data.merchant.email}`} 
            </div>
          </div>
        </div>
      
      <div id = "InvoiceLineBreaker2" className="bg-[#04394f] sm:flex md:hidden h-[1px]" />
        <div id = "InvoiceHeader" className = "block bg-white rounded-t-[10px] sm:px-0 sm:py-[10px] md:py-[0.5rem] md:px-0">
            <div className="flex md:w-full sm:w-full pt-2">
              <div className="sm:flex md:block sm:w-full sm:justify-between items-center text-slate-800 text-[14px] font-medium">    
                <div className="md:text-[20px] sm:text-[16px] w-full">
                    <div className="w-full sm:block lg:flex">
                          <div className="hidden">{data.merchant.is_billing 
                            ? window.document.title = `${translation('BILL_ID')} ${data.invoice.number}` 
                            : window.document.title = `${translation('INVOICE')} ${data.invoice.number}`}</div>
                          <div className="sm:w-full lg:w-2/3 text-slate-600 font-[700] sm:text-center sm:text-[18px] lg:text-left md:text-[18px]">
                            {data.merchant.is_billing 
                            ? `${translation('BILL_ID')} ${numberWithSpaces(data.invoice.number)}`
                            : `${translation('INVOICE')} ${numberWithSpaces(data.invoice.number)}`}
                          </div>
                          <div className="sm:w-full lg:w-1/3 text-slate-600 sm:text-center sm:text-[14px] lg:text-right md:text-[18px]">{translation('DATE')} {data.invoice.created.substring(0, data.invoice.created.indexOf('T'))}</div>    
                    </div>
                </div>
              </div>
            </div>
            </div>
            <div>
                  <div className="text-center flex bg-white justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-500">
                    <div className="block w-2/3 text-left">
                      <div >{translation('BILL_TO')}</div>
                      <div>{data.invoice.bill_to}</div>
                      <div>{!data.merchant.is_billing && data.invoice.performer}</div>
                    </div>
                    <div className="block w-1/3 text-right">
                      <div >{translation('PHONE')}</div>
                      <div> {data.invoice.phonenumber}</div>
                    </div>
                  </div>
            </div>
            <div className="sm:block md:flex bg-white justify-between sm:px-[20px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-0 text-slate-600">
              <div className="text-justify text-[18px] font-[500] md:w-2/3">
                {data.merchant.is_billing ? (data.invoice.amount_to_pay >0 ? translation('UNPAID') : 'Пешпардохти муштарӣ' ) : data.invoice.subject[i18n.language]}
                {/*cleanText(data.subject[i18n.language] !== '' ? data.subject[i18n.language] : data.subject['tj'])*/}
              </div>
              <div className="sm:w-full text-[24px] font-[800] text-center md:w-1/3 md:text-right">
                {
                   `${data.merchant.is_billing ? 
                      numberWithSpaces((data.invoice.amount_to_pay/100).toFixed(2))
                      : numberWithSpaces((data.invoice.amount_due/100).toFixed(2))
                    } ${translation('CURRENCY_1')}`
                   
                }
              </div>
            </div>
            
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Маълумоти муфассал" key="1">
              <div className="sm:px-[20px] sm:pb-[10px] mb-4 sm:pt-0 md:pt-[10px] text-slate-600">
            
              {data.payments.map ((item, index) =>(
                <div key={index} className="flex border-b-[1px] border-slate-400">
                  <div className="w-[75%] text-left">{`${index+1}. ${item.subject}`}</div>
                  <div className={`w-[25%] text-right`}>{numberWithSpaces((item.payment_amount/100).toFixed(2))}</div>
                </div>
              ))}
              
              
            
            {/*<div id = "InvoiceSummary" className="flex border-b-[1px] border-slate-400 py-[2px] font-semibold text-red-600">
              <div className="w-[75%] text-left">{translation('UNPAID')} </div>
              <div className="w-[25%] text-right font-bold text-red-600"> {numberWithSpaces((data.amount_to_pay/100).toFixed(2))} </div>
              
            </div>*/}
            </div>
            {data.invoice.app_timeline && 
              <div className="flex">
              <Timeline className='pt-2' mode={'left'} items={data.invoice.app_timeline}>
              </Timeline>
              </div>
            
            }
              </Panel>
            </Collapse>
            
          {data.invoice.status === 'open' ? <>
            {data.invoice.contract ?
            <div className="my-5">
            <div className="flex mt-5 justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] font-semibold py-2 px-8  bg-emerald-500 hover:bg-emerald-400 hover:text-white" 
                 onClick={this.onShowFile}> 
                 <div className="">Ҳисобнома фактура PDF</div>
          
               </button>
              </div>
            </div>
            : isMobile && data.invoice.amount_to_pay >0 ? <div className="my-5">
              <div className="text-slate-600 font-semibold">{`${translation('PAY_EW')} ${translation('EWALLET')}`}</div>
              {/* Amonatbonk */}
              <div className="flex mt-5 justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] font-semibold py-2 px-8  bg-emerald-500 hover:bg-emerald-400 hover:text-white" 
                 onClick={()=>{
                  window.location.href=`amonatbonk://resolve?view=pay&payID=khs&number=${data.invoice.number.replace('-','')}&sum=${(data.invoice.amount_to_pay /100).toFixed(2)}`
                }}> 
                 <div className="">Амонат Мобайл</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-white items-center text-center">
                  <img alt='' className='m-auto h-[30px] rounded-full' src='/assets/img/amonatbonk.jpg'></img>
                 </div>
               </button>
              </div>

              {/* DC Bank */}
              <div className="flex justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] font-semibold py-2 px-8  bg-orange-600 hover:bg-orange-400 hover:text-white hover:font-bold" 
                 onClick={()=>{window.location.href = `http://pay.expresspay.tj/?A=${data.invoice.number.replace('-','')}&s=${(data.invoice.amount_to_pay /100).toFixed(2)}&c=&f1=318`}}> 
                 <div >DC Next</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-white items-center text-center">
                  <img alt='' className='m-auto h-[30px]' src='/assets/img/dc-next.png'></img>
                 </div>
               </button>
              </div>

              {/* Alif Bank */}
              <div className="flex justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] font-semibold py-2 px-8  bg-teal-600 hover:bg-teal-500 hover:text-white hover:font-bold" 
                 onClick={()=>{
                  window.location.href = `https://alifmobi.page.link/providers?id=2268&amount=${((data.invoice.amount_to_pay - data.invoice.amount_paid)/100).toFixed(2)}&account=${data.invoice.number.replace('-','')}`
                 }}> 
                 <div >Alif Mobi</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-white items-center text-center">
                  <img alt='' className='m-auto h-[30px]' src='/assets/img/alif_logo.webp'></img>
                 </div>
               </button>
              </div>

              {/* Eskhata Bank */}
              <div className="flex justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] py-2 px-8  font-semibold bg-blue-600 hover:bg-blue-400 hover:text-white hover:font-bold" 
                 onClick={()=>{window.location.href=`eskhata://service/8406e7f9-44c6-440e-9021-3f41dd01f5ff/${data.invoice.number.replace('-','')}/${((data.invoice.amount_to_pay - data.invoice.amount_paid)/100).toFixed(2)}`
                }}> 
                 <div >Эсхата Онлайн</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-white items-center text-center">
                  <img alt='' className='m-auto h-[30px] rounded-full' src='/assets/img/eskhata.webp'></img>
                 </div>
               </button>
              </div>

              {/* Bank Arvand */}
              <div className="flex justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] py-2 px-8  font-semibold bg-[#069ea1] hover:bg-[#069ea1] hover:text-white hover:font-bold" 
                 onClick={()=>{window.location.href=`arvand://resolve?view=pay&payID=khs&number=${data.invoice.number.replace('-','')}&sum=${((data.invoice.amount_to_pay - data.invoice.amount_paid)/100).toFixed(2)}`
                }}> 
                 <div >Арванд</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-white items-center text-center">
                  <img alt='' className='m-auto h-[30px] rounded-full' src='/assets/img/arvand.webp'></img>
                 </div>
               </button>
              </div>

              {/* Humo Bank */}
              <div className="flex justify-center pb-4">
               
                <img alt='' className='sm:w-[320px] cursor-pointer m-auto hover:opacity-75' src='/assets/img/humo.svg'
                  onClick={()=>{window.location.href=`humoonline://humo.tj/transfers?type=service&account=${data.invoice.number}&amount=${((data.invoice.amount_to_pay - data.invoice.amount_paid)/100).toFixed(2)}&id=667&has_precheck=true`}}
                ></img>
                 
              </div>

              
              {/* Commercebank */}
              <div className="flex justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] py-2 px-8  font-semibold bg-green-500 hover:bg-green-300 hover:text-white" 
                 onClick={()=>{
                  window.location.href=`cbt://resolve?view=pay&payID=khs&number=${data.invoice.number.replace('-','')}&sum=${(data.invoice.amount_to_pay /100).toFixed(2)}`
                }}> 
                 <div className="">Фаврӣ</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-white items-center text-center">
                  <img alt='' className='m-auto h-[30px] rounded-full' src='/assets/img/cbt.webp'></img>
                 </div>
               </button>
              </div>

              {/* MyBabilon */}
              <div className="flex justify-center pb-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[16px] py-2 px-8  font-semibold bg-[#feca01] hover:bg-[#feca01] hover:text-white hover:font-bold" 
                 onClick={()=>{window.location.href=`https://my1.babilon-m.tj/smartpay?id=596&amount=${(data.invoice.amount_to_pay /100).toFixed(2)}&account=${data.invoice.number.replace('-','')}`
                }}> 
                 <div className="text-sky-800">MyBabilon</div>
                 <div className="flex ml-8 rounded-full h-[34px] w-[34px] bg-sky-800 items-center text-center">
                  <img alt='' className='m-auto h-[30px] rounded-full' src='/assets/img/mybabilon.png'></img>
                 </div>
               </button>
              </div>
               

              

              </div>
              :<>
                
              </>
            }
            {/*<div className="flex justify-center sm:pb-4 md:py-4">
               <button className="sm:w-[320px] flex justify-center items-center text-center text-white rounded-[12px] md:text-[18px] sm:text-[14px] py-2 px-8  bg-indigo-700 hover:bg-indigo-500 hover:text-white hover:font-bold" 
                 onClick={() => this.startPayment()}> 
                 <div >{translation('PAY_EW')}</div>
                 <img alt='' className='pl-4 h-[32px]' src='/assets/img/korty_milly2.png'></img>
                 <img alt='' className='pl-4 h-[16px]' src='/assets/img/visa_white.png'></img>
               </button>
            </div>*/}
        </>
        :<>
          {data.invoice.status === 'void' ?(
                  <>
                    <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                      <div className="void_stamp"> {translation('VOID_STAMP')}</div>
                    </div>
                  </>

              ):(

                <>
                  <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                  {data.invoice.amount_due === 0 ? (
                      <div className="void_stamp text-center"> ТАХФИФ 100% </div>
                    ):(
                      <div className="stamp_m text-center"> {translation('PAYED_STAMP')}</div>
                    )
                  }
                  </div>
                  
                  
                </>
              )
            }
</>
                
          }
            

            
            
            
          </div>
      }
          <div className="md:h-40"></div>
      </div>
      
      

      
    
      </div>
      );

  } 
  
}


export default withRouter(MobileInvoiceForm) ;