import React, { useState } from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import "./mainlayout.css";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { TJ, RU } from "country-flag-icons/react/3x2";
import { Footer } from "./Footer";
import { t } from "i18next";
//import Prefooter from "./Prefooter";
import SearchBar from "../MobilePage/components/searchbar";

const setActiveLink = (isActive) =>
  isActive
    ? "text-white uppercase font-[600]"
    : "text-sky-300 uppercase font-[600] hover:text-white transition-all easy-in-out duration-150";

function SecondLayout() {
  const { i18n } = useTranslation();
  //const [selectedLang, setSelectedLang] = useState("tj");
  const [isLangBarExpanded, setLangBarExpanded] = useState(false);

  function changeLanguage(lang) {
    //setSelectedLang(lang);
    i18n.changeLanguage(lang);
    setLangBarExpanded(!isLangBarExpanded);
  }

  function CurrLang() {
    return (
      <>
        {i18n.language === "tj" ? (
          <TJ style={{ width: "4rem" }} />
        ) : (
          <RU style={{ width: "4rem" }} />
        )}
      </>
    );
  }

  return (
    <div
      id="main_container"
      className="main_container anima w-full h-screen flex flex-col items-start justify-between overflow-y-auto"
    >
      {!isLangBarExpanded ? (
        !isMobile ? (<>
        <div id="myheader" className="z-10 w-full bg-transparent py-7">
          <div className="w-[95%] mx-auto max-w-[1320px] flex flex-row justify-between items-center">
            <Link to="/">
              <img alt='' className="w-[230px]" src="/assets/img/smartpay_logo_color.png"/>
            </Link>

            <nav className="flex flex-row items-center space-x-8">
              <NavLink
                className={({ isActive }) => setActiveLink(isActive)}
                to="/gover"
              >
                {t('MAIN_PAGE')}
              </NavLink>
              <NavLink
                className={({ isActive }) => setActiveLink(isActive)}
                to="gover"
              >
                {t('GOV_PAGE')}
              </NavLink>
              <NavLink
                className={({ isActive }) => setActiveLink(isActive)}
                to="business"
              >
                {t('BUSINESS_PAGE')}
              </NavLink>
              <NavLink
                className={({ isActive }) => setActiveLink(isActive)}
                to="banks"
              >
                {t('BANK_PAGE')}
              </NavLink>
              <NavLink
                className={({ isActive }) => setActiveLink(isActive)}
                to="demostore"
              >
                Демо
              </NavLink>
            </nav>
            <div className="w-[25%]">
                <SearchBar />
            </div>
            

            <div
              className="flagm flex cursor-pointer items-center"
              onClick={() => {
                if (document.getElementById("SearchBar") != null) {
                  document.getElementById("SearchBar").style.visibility =
                    "hidden";
                }
                setLangBarExpanded(!isLangBarExpanded);
              }}
            >
              <CurrLang></CurrLang>
            </div>
          </div>
        </div>
        </>) : <>
        <div id="myheader2" className="z-10 w-full bg-transparent sm:pt-7 md:py-7">
          <div className="w-[95%] mx-auto max-w-[1320px] flex flex-row justify-between items-center">
            <Link to="/">
              <img alt='' className="w-[230px]" src="/assets/img/smartpay_logo_color.png"/>
            </Link>
            <div
              className="flagm flex cursor-pointer items-center"
              onClick={() => {
                if (document.getElementById("SearchBar") != null) {
                  document.getElementById("SearchBar").style.visibility =
                    "hidden";
                }
                setLangBarExpanded(!isLangBarExpanded);
              }}
            >
              <CurrLang></CurrLang>
            </div>
          </div>
          <div className="m-10"><SearchBar /></div>
          
        </div>
        </>
        
      ) : (
        <div className="z-10 flex w-full /*bg-[#04394f]*/ bg-transparent justify-between sm:h-[5rem] md:h-[5rem] lg:h-[6rem]">
          <div className="flex w-full justify-around sm:h-[4rem] md:h-[5rem] lg:h-[6rem]">
            <div className="flex text-white text-[18px] uppercase font-semibold items-center">
              <div
                className="flag_bar_item"
                onClick={() => {
                  if (document.getElementById("SearchBar") != null) {
                    document.getElementById("SearchBar").style.visibility =
                      "visible";
                  }
                  changeLanguage("tj");
                }}
              >
                <TJ style={{ width: "4rem" }} />
              </div>
              Тоҷикӣ
            </div>
            <div className="flex text-white text-[18px] uppercase font-semibold items-center">
              <div
                className="flag_bar_item"
                onClick={() => {
                  if (document.getElementById("SearchBar") != null) {
                    document.getElementById("SearchBar").style.visibility =
                      "visible";
                  }
                  changeLanguage("ru");
                }}
              >
                <RU style={{ width: "4rem" }} />
              </div>
              Русский
            </div>
          </div>
        </div>
      )}

      <Outlet />
      {/*<Prefooter /> */}
      <Footer />
    </div>
  );
}

export default SecondLayout;
