import React  from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
//import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

export function numberWithSpaces(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export const withRouter = WrappedComponent => props => {
    const params = useParams();
    const { t } = useTranslation('','', '', { useSuspense: false });
    
    return (
      <WrappedComponent
        {...props}
        params={params}
        translation = {t}
        
        
      />
    );
  };

export function API_KEY() {
  const d = new Date();
  const time = (d.getTime()/1000).toFixed(0);
  
  var iv = CryptoJS.lib.WordArray.random(16);
  //var key = CryptoJS.enc.Utf8.parse( 'secret' + time ); 
  var key = CryptoJS.enc.Base64.parse("aR1h7EefwlPNVkvTHwfs6w==");
  
  var encrypted = CryptoJS.AES.encrypt(
    '5fe322089e65f10c4f82e6aee55ba4f095012a90afcca9c97098325695107d40' + time,
      key,
      { iv: iv }
    );
  var joinedData = iv.clone().concat(encrypted.ciphertext);
  var joinedDataB64 = CryptoJS.enc.Base64.stringify(joinedData);
  console.log(joinedDataB64);
  return joinedDataB64;
    
}