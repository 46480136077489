import React from "react";
import { useTranslation } from "react-i18next";
import "./mobile.css";
import astronauts from '../../assets/img/astronauts.png';



export default function Notfound (props) {
    const {t} = useTranslation();
    return (
        <div className="flex mt-10 w-full justify-center items-center home">
        <div className="error404">
            <img alt='' src = {astronauts}></img>
            <p className="number">{props.status.status}</p>
            
            <p>{t('404')}</p>
            
        </div>
        </div>
    );

}