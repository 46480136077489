import React, { Component } from "react";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Collapse, Form, Input, message } from 'antd'; 
import { Link } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthCode from 'react-auth-code-input';

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

export class ServiceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            requestFailed: false,
            data: null,
            merchantId: null,
            catId: null,
            qty: 1,
            phone: '',
            isRecaptcha: null,
            token: '',
            refreshReCaptcha: false,
            isPhoneEntered: null,
            count: 1,
        };
    };

    componentDidMount() {
        this.get_data();
    }; 

    componentDidUpdate() {
    
    };

    setTokenFunc = (getToken) => {
        this.setState({token: getToken});
    };

    onFinish=()=>{
        //console.log(`Phone: ${this.state.phone}, ServiceId: ${this.props.params.serviceId}, Qty: ${this.state.qty}, CatId: ${this.props.params.catId}`)
        //this.setState({isRecaptcha: false});
        fetch(`https://smartpay.tj/api/v1/invoices/sue/new`, 
            { 
                method: "POST", 
                headers : {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'x-app-token': API_KEY(),
                },
                body: JSON.stringify({
                    'service_id': parseInt(this.props.params.serviceId),
                    'cat_id': parseInt(this.props.params.catId), 
                    'phone': this.state.phone,
                    'qty': this.state.qty,
                    'ticket': false,
                    'price': 0,
                    'seans': ''
                  })
            })
        
            .then((response) => {
              if(response.status === 200){
                  return response.json();     
              }else {
                  this.setState({ requestFailed: true })
                  message.error('Дархост қайд нашуд');
                  //window.location.href = 'https://smartpay.tj/404'
              }
            })
                .then((responseJson) => {
                  window.location.href = `https://smartpay.tj/invoice/${responseJson.invoice_id}`
                });
    }

    get_data = () => {
        fetch(`https://smartpay.tj/api/v1/services/sue?merchantId=${this.props.params.merchantId}&catId=${this.props.params.catId}`, 
            { 
                method: "GET", 
                headers : {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'x-app-token': API_KEY(),
                }
            })
        
            .then((response) => {
              if(response.status === 200){
                  return response.json();     
              }else {
                  this.setState({ requestFailed: true })
                  //window.location.href = 'https://smartpay.tj/404'
              }
            })
                .then((responseJson) => {
                  this.setState({ data : responseJson, isLoaded: true });
                });
    }

    render () {
        const { isLoaded, data, qty, phone, isRecaptcha, isPhoneEntered } = this.state;
        const { Panel } = Collapse;
        const { translation } = this.props;

        return(
            <div id = "InvoiceContainer" style={{textAlign: "-webkit-center"}} className="block bg-white text-center">
                {!isLoaded ?<div className="flex w-full h-[80vh] justify-center items-center">
                    <Spin size="large" indicator={antIcon} />
                </div>
                :<>
                <div className="flex md:w-[55%] mt-4">
                    <div className="flex w-[25%] ml-4 justify-center items-center">
                        <img alt='' className="sm:!h-[86px]" src={data.logo}></img>
                    </div>
                    <div className="w-[75%] block  text-center">
                        <div className="mx-2 sm:text-[14px] md:text-[18px] upper font-semibold">{data.name}</div>
                        <div className="mx-2 sm:text-[11px] md:text-[13px] text-center py-[5px]" >
                        {data.address} {`${translation('TEL')} ${data.phone} ${translation('EMAIL')} ${data.email}`} 
                        </div>
                    </div>
                </div>

                { this.props.params.serviceId
                ? <>
                    {data.category.map ((item, _) =>(
                        item.services.map((service, _)=>(
                          service.id === parseInt(this.props.params.serviceId) && 
                          <>
                          <Form id="appform" className="" form={this.props.form} onFinish={this.onFinish}>
                          <div className="mx-4 md:w-1/2">
                            <div className="flex mt-4">
                                <div className="w-[30%] text-left">Хизматрасонӣ</div>
                                <div className="w-[70%] text-right">{service.name}</div>
                            </div>
                            <div className="flex mt-4">
                                <div className="w-[30%] text-left">Нарх</div>
                                <div className="w-[70%] text-right">{numberWithSpaces(service.price.toFixed(2))}</div>
                            </div>
                            <div className="flex mt-4">
                                <div className="w-[30%] text-left">Адад</div>
                                <div className="w-[70%] text-right">
                                <Form.Item name='qty' className="m-0">
                                {/* <Input
                                    onChange={(e)=>{this.setState({qty: e.target.value})}}
                                    defaultValue={1}
                                    className="text-center bg-white border px-2 py-1 rounded-[4px] w-1/3 mx-auto"
                                    type="text"
                                    name="qty"
                                    required
                                /> */}
                                    <div className="flex w-2/3 justify-between items-center">
                                    <div onClick={() => {if (qty > 0.5) {this.setState(prevState =>{
                                                return{...prevState, qty : prevState.qty - 0.5}
                                             });}}}
                                        className="w-[38px] h-[40px] bg-blue-500 active:bg-[#212462] rounded-[7px] cursor-pointer align-middle text-center text-[#ffffff] text-[24px] font-[500]"
                                    >
                                        -
                                    </div>
                                    <p className="font-[500] text-[22px] text-black">
                                        {qty}
                                    </p>
                                    <div
                                        onClick={() => this.setState(prevState =>{
                                            return{
                                                 ...prevState,
                                                 qty : prevState.qty + 0.5
                                            }
                                         })}
                                        className="w-[38px] h-[40px] bg-blue-500 active:bg-[#212462] rounded-[7px] cursor-pointer align-middle text-center text-[#ffffff] text-[24px] font-[500]"
                                    >
                                        +
                                    </div>
                                    </div>


                                </Form.Item>
                                </div>
                            </div>
                            <div className="flex mt-4">
                                <div className="w-[30%] text-left font-semibold">Ҳамагӣ</div>
                                <div className="w-[70%] text-right font-semibold">{numberWithSpaces((qty * service.price).toFixed(2))}</div>
                            </div>

                            <div className="mt-4">
                                <div className="text-center font-semibold">Рақами телефон барои гирифтани смс паёмак</div>
                                <Form.Item name='phone' className="m-0" required>
                                <AuthCode 
                                    inputClassName="sm:w-[25px] sm:h-[40px] sm:text-[16px] sm:rounded-[5px] sm:mx-[1.5px] md:w-[35px] md:h-[45px] md:text-[24px] text-center md:mx-1 md:rounded-[8px] border-solid border-[1px] border-[#d6d6d6]"
                                    onChange={(value)=>{
                                            this.setState({phone: value});
                                            //console.log(value, value.length);
                                            if (value.length===9) {
                                                this.setState({isPhoneEntered: true}); 
                                            } else {this.setState({isPhoneEntered: false})};
                                        }
                                    }
                                    name="phone"
                                    length={9}
                                    allowedCharacters="numeric" 
                                />
                                </Form.Item>
                            </div>
                            <div className="mt-[40px]">
                            
                            </div>
                            <div className="mt-4">
                            <button
                                className={`${
                                    (isPhoneEntered) ? "visible sm:w-[70%] md:m-8 my-2 px-4 py-2 bg-indigo-500 text-white rounded-md md:w-[200px] hover:bg-indigo-600":"hidden"
                                     
                                }`}
                                type="submit"
                                form="appform"
                                >
                                    {translation("PAY")}
                            </button>
                            </div>

                            
                          </div>
                          <GoogleReCaptchaProvider reCaptchaKey={'6LdbjS0qAAAAAFrFSN5HHvRkffPfeG-078Hbadb_'}>
                                <GoogleReCaptcha
                                    className="google-recaptcha-custom-class"
                                    onVerify={this.setTokenFunc}
                                    //refreshReCaptcha={(e)=>{this.setState({refreshReCaptcha: false})}}
                                />
                            </GoogleReCaptchaProvider>
                        </Form>
                        </>     

                            
                          
                        )

                        )
                    ))

                    }
                
                </> 
                :<>
                

                {data.category.map ((item, index) =>(
                    <Collapse accordion className="mx-4 my-4 md:w-1/2">
                    <Panel header={item.name} key={index} className="">
                        {
                            item.services.map((service,ind)=>(
                                <Link to={`${service.id}`}>
                                    <div key={ind} className="flex m-4 rounded-[10px] bg-indigo-600">
                                        <div className="p-2 text-white text-center">{`${ind+1}. ${service.name} - ${numberWithSpaces(service.price.toFixed(2))} смн.`}</div>
                                    </div>
                                </Link>
                            )
                        )
                        }
                        
                    </Panel>
                    </Collapse>
                ))}
                </>
                }
                </>
                
                }
            </div>
        )
    };
}

export default withRouter(ServiceForm);