import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import { FaArrowRightLong, FaArrowDownLong } from "react-icons/fa6";

function Buziness() {
  const [percent, setPercent] = useState(25);

  useEffect(() => {
    setTimeout(() => {
      const storeBlock = document.querySelector(".online_store");
      const payBtn = document.querySelector(".payStore");
      const wallet = document.querySelector(".wallet");
      const payInWallet = document.querySelector(".payBtn");
      const payedBlock = document.querySelector(".payedBlock");
      const payedText = document.querySelector(".payedText");

      storeBlock.classList.remove("hidden");
      storeBlock.classList.add("flex");
      storeBlock.style.animation = "showInUp2 2s ease-in-out";

      setTimeout(() => {
        payBtn.style.animation = "clickBtnEffect2 1s ease-in-out";
        setTimeout(() => {
          storeBlock.style.animation = "showInRight 1s ease-in-out";
          setTimeout(() => {
            storeBlock.classList.add("hidden");
            wallet.classList.remove("hidden");
            wallet.style.animation = "showInLeft 1s ease-in-out";

            setTimeout(() => {
              payInWallet.style.animation = "clickLink 1s ease-in-out";

              setTimeout(() => {
                wallet.style.animation = "showInRight 1s ease-in-out";
                setTimeout(() => {
                  wallet.classList.add("hidden");
                  payedBlock.classList.remove("hidden");
                  payedBlock.classList.add("flex");
                  payedBlock.style.animation = "showInLeft 1s ease-in-out";
                  setTimeout(() => {
                    setPercent(55);
                    setTimeout(() => {
                      setPercent(100);
                      payedText.classList.remove("hidden");
                      payedText.style.animation = "showInLeft 1s ease-in-out";
                    }, 200);
                  }, 500);
                }, 600);
              }, 1000);
            }, 1500);
          }, 700);
        }, 800);
      }, 3000);
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const leftColumn = document.querySelector(".leftColumn");
      const toRight = document.querySelector(".toRight");
      const rightColumnTop = document.querySelector(".rightColumnTop");
      const toBottom = document.querySelector(".toBottom");
      const rightColumnBottom = document.querySelector(".rightColumnBottom");

      leftColumn.classList.remove("hidden");
      leftColumn.style.animation = "showInLeft2 1s ease-in-out";

      setTimeout(() => {
        toRight.classList.remove("hidden");
        toRight.style.animation = "showInLeft2 1s ease-in-out";

        setTimeout(() => {
          rightColumnTop.classList.remove("hidden");
          rightColumnTop.classList.add("flex");
          rightColumnTop.style.animation = "showInLeft2 1s ease-in-out";

          setTimeout(() => {
            toBottom.classList.remove("hidden");
            toBottom.style.animation = "showInUp2 1s ease-in-out";

            setTimeout(() => {
              rightColumnBottom.classList.remove("hidden");
              rightColumnBottom.classList.add("flex");
              rightColumnBottom.style.animation = "showInUp2 1s ease-in-out";
            }, 1000);
          }, 1400);
        }, 1000);
      }, 1300);
    }, 3000);
  }, []);
  return (
    <div className="w-[90%] max-w-[1320px] mx-auto my-8">
      <div className="w-[85%] flex flex-row justify-between items-start mt-4">
        <div className="w-[60%] flex flex-col items-start space-y-7 mt-4">
          <h2 className="text-[28px] font-[700] text-slate-900 m-0">
            Инфраструктура для онлайн платежей
          </h2>

          <ul className="text-[19px] font-[600] text-slate-900 list-disc flex flex-col space-y-7 leading-8">
            <li>Онлайн эквайринг</li>

            <li>Оплата эл.кошелками</li>

            <li>Биллинг и финансовый учет</li>
          </ul>
        </div>

        <div className="w-[290px] h-[530px] rounded-[42px] bg-slate-800 drop-shadow-2xl p-2">
          <div className="w-full h-full rounded-[38px] bg-white border border-slate-300 overflow-hidden">
            <div className="w-full h-[75%] flex-col justify-center items-center online_store hidden">
              <img src="/assets/img/nike_lbj23.png" alt="nike" width={150} />

              <p className="text-[18px] font-[700] text-slate-900 m-0">
                Nike LBJ 23 (42 - размер)
              </p>
              <div className="w-[90%] mx-auto flex flex-row justify-between items-center my-8">
                <p className="text-[16px] font-[600] text-slate uppercase">
                  Цена:
                </p>
                <p className="text-[18px] font-[600] text-slate-900">550 TJS</p>
              </div>
              <button className="w-[90%] mx-auto h-[48px] rounded-[12px] text-center font-[600] text-lime-500 border border-lime-500 bg-transparent payStore">
                Оплатить
              </button>
            </div>

            <div className="w-[90%] mx-auto mt-10 hidden wallet">
              <p className="text-[18px] font-[600] text-slate-900">Ваш Банк</p>
              <div className="mt-6 w-full flex flex-col space-y-8 items-center">
                <img
                  width="100%"
                  height={130}
                  src="/credit-card.svg"
                  alt="card"
                />

                <div className="w-full h-[36px] border border-green-300 rounded-[8px] px-3 pt-1 overflow-hidden">
                  <p className="text-[17px] font-[600] text-slate-800">
                    550.00
                  </p>
                </div>
                <div className="w-full pt-10">
                  <button className="w-full h-[46px] rounded-[12px] bg-lime-500 text-white font-[600] text-center payBtn">
                    Оплатить
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full h-[80%] flex-col justify-center items-center payedBlock hidden">
              <div>
                <Progress type="circle" percent={percent} />

                <p className="text-[18px] font-[600] uppercase text-lime-500 mt-5 ml-2.5 payedText hidden">
                  Оплачено
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-12 mt-20 w-full flex flex-row justify-between items-start">
        <div className="w-[50%] flex flex-col items-start space-y-7 mt-7">
          <h2 className="text-[28px] font-[700] text-slate-900 m-0">
            Инвойсирование
          </h2>

          <ul className="text-[19px] font-[600] text-slate-900 list-disc flex flex-col space-y-7 leading-8">
            <li>Выставление электронного счета на оплату</li>

            <li>Прием оплаты через банковские карты или эл.кошелки</li>

            <li>Интеграция с 1С</li>
          </ul>
        </div>

        <div className="w-[49%] flex flex-row justify-between items-start mt-5">
          <div className="w-[43%] leftColumn hidden">
            <div className="w-full border rounded-[8px] px-3 py-3.5 flex flex-col space-y-2 items-center drop-shadow-lg">
              <p className="text-[16px] font-[500] text-slate-900">
                Выставление электронного счета на оплату
              </p>
            </div>
          </div>
          <FaArrowRightLong className="mt-7 hidden toRight" size={30} />

          <div className="w-[43%] flex flex-col items-center space-y-7 overflow-hidden min-h-[260px]">
            <div className="w-full border rounded-[8px] px-3 py-3.5 flex-col space-y-2 items-center drop-shadow-lg hidden rightColumnTop">
              <p className="text-[16px] font-[500] text-slate-900">
                Прием оплаты через банковские карты или эл.кошелки
              </p>
            </div>

            <FaArrowDownLong className="hidden toBottom" size={30} />

            <div className="w-full border rounded-[8px] px-3 py-3.5 flex-col space-y-2 items-center drop-shadow-lg hidden rightColumnBottom">
              <p className="text-[16px] font-[500] text-slate-900">
                Интеграция с 1С
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buziness;
