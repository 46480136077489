import React from "react";
import i18n from 'i18next';

import { Component } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'; 

import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"


const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );


export class ReceiptForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoaded: false,
        data: null,
        payler_session: null,
        key: 'smartpay',
        invoice_total: 0,
        requestFailed: false,
    };
  }

  componentDidMount() {
    
    this.getData();
    
  }

  

  componentDidUpdate() {
    
  }

  getData = () => {
    
    fetch('https://smartpay.tj/subapi/payler/invoice/uuid/' + this.props.params.invoiceId, 
    { 
        method: "GET", 
        headers : {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'x-app-token': API_KEY(),
        }
    })

    .then((response) => {
      if(response.status === 200){
          return response.json();     
      }else {
          this.setState({ requestFailed: true })
          window.location.href = 'https://smartpay.tj/404'
      }
    })
        .then((responseJson) => {
          this.setState({ data : responseJson, isLoaded: true });
          
        });
  }

  startPayment = () => {
    let total_amount = this.state.data.amount_to_pay;
    //if(!this.state.data.bank_fee_included){
    //  total_amount = (this.state.data.amount_due / (1-this.state.data.bank_fee)).toFixed(0);
    //}
    

    fetch("https://smartpay.tj/subapi/payler/payment_session", 
    {
      method: "POST",
      credentials:"include",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
        'x-app-token': API_KEY(),
      },
      


      body: JSON.stringify({
        'amount': total_amount,
        'product': (this.state.data.subject[i18n.language] === null ? this.state.data.subject['tj'] : this.state.data.subject[i18n.language]),
        'lifetime': 43200,
        'invoice_number': this.state.data.invoice_uuid,
        'currency': 'TJS',
        'test_mode': this.state.data.test_mode,
      })

    }
    
    )
    
    .then((response) => response.json())
      .then((responseJson) => {
        
        window.location.href = responseJson.payment_url;
        
    });
  
  }

  render () {
    const { isLoaded, data } = this.state;
    const { translation } = this.props;
    
    return (
      <div>
      
      <div id = "SubHeader" className="flex text-blue-50 bg-transparent">
        
      </div>
      <div id = "InvoiceContainer" className="block bg-white rounded-[10px] xl:mx-[15rem]">
        {!isLoaded 
          ? <Spin indicator={antIcon} />
          : <div>
            
              <div className="flex flex-wrap md:w-full sm:mt-[0rem] md:mt-0">
                <div className="block w-full m-auto">
                  <div className="flex w-full items-center justify-center">
                    <div className="flex m-auto bg-white rounded-full md:border-0 
                                  sm:shadow-none md:shadow-none">
                      <img alt='' className="md:h-[72px] sm:h-[56px]" src={data.merchant_logo}></img>
                    </div>
                  </div>
                  <div className="items-center text-black w-full sm:text-[14px] md:text-[16px] font-medium">
                      <div className="block w-full bg-white text-center">
                        <div className="upper text-[14px] font-semibold pt-[10px]">{data.merchant_name[i18n.language]}</div>
                      </div>
                  </div>
                  
                  <div className="w-full">
                    <div id = "InvoiceLineBreaker2" className="sm:flex md:hidden h-[2px] bg-black" />
                    <div className="flex bg-white text-center">
                      <div className="items-center justify-center text-black w-full sm:px-[10px] md:px-0">
                      
                        <div className="text-[12px] py-[5px]" >
                          {`${data.merchant_address}, ${translation('TEL')} ${data.merchant_phonenumber} 
                            ${translation('EMAIL')} ${data.merchant_email}`
                          }
                            
                        </div>
                      </div>
                    </div>
                    
                    <div id = "InvoiceLineBreaker2" className="sm:flex h-[1px] bg-black" />
                  </div>
                </div>
                
              </div>
              
              <div className="flex flex-wrap md:w-full sm:w-full ">
                <div className="sm:flex md:block sm:w-full md:w-auto m-auto  text-black text-[14px] font-medium">
                  
                  <div className="md:text-[18px] sm:text-[14px]">
                    {data.status === 'open'
                      ? <div className="sm:block md:flex">
                          <div className="hidden">{window.document.title = translation('INVOICE') +  ' ' + data.invoice_number}</div>
                          <div className="px-[10px]">{translation('INVOICE')}</div>
                          <div className="px-[10px]">{numberWithSpaces(data.invoice_number)}</div>
                          
                        </div>
                      : <div className="sm:block md:flex">
                          <div className="hidden">{document.title = translation('RECEIPT') +  ' ' + data.invoice_number}</div>
                          <div className="px-[10px]">{translation('RECEIPT') }</div>
                          <div className="px-[10px]">{numberWithSpaces(data.invoice_number)}</div>
                        </div>
                    }
                  </div>
                  <div className="sm:block md:flex">
                    <div className="px-[10px]">{translation('DATE')}</div> 
                    <div className="px-[10px]">{data.create_at.substring(0, data.create_at.indexOf('T'))}</div>
                  </div>
                  <div className="sm:block md:flex">
                    <div className="px-[10px]">{translation('TIN')}</div>
                    <div className="px-[10px]">{data.merchant_tin}</div>
                  </div> 
                  
                </div>
              </div> 
            
            {!data.bill_to && !data.customer_phonenumber
              ?<div></div>
              : <div>
                  <div className="sm:h-0 md:h-[1px] bg-black" /> 
                  <div className="sm:block md:flex bg-white justify-between sm:px-[10px] sm:pb-[10px] sm:pt-0 md:pt-[10px] md:px-[4rem] text-black">
                    <div >{translation('BILL_TO')}: {data.bill_to}</div>
                    <div >{translation('PHONE')}: {data.customer_phonenumber}</div>  
                  </div>
                </div>
            }
            
            <div className="h-[2px] bg-black" /> 
            <div id = "InvoiceDataHeader" className="flex bg-slate-100 md:px-[4rem] py-[10px] sm:px-[10px] sm:py-[5px] text-[13px] font-medium text-black">
              <div className="md:w-[50vw] sm:w-[40vw] text-left"> {translation ('DESCRIPTION')} </div>
              <div className="w-[10vw] text-center"> {translation ('Q_TY')} </div>
              {/*<div className="w-[10vw] text-center"> {translation ('MEASURE')} </div>*/}
              <div className="md:w-[15vw] sm:w-[20vw] text-right"> {translation ('PRICE')} </div>
              <div className="md:w-[15vw] sm:w-[20vw] text-right"> {translation ('AMOUNT')} </div>
            </div>
            <div className="h-[1px] bg-black sm:mx-[10px] md:mx-[3.5rem] " />
            <div> 
              { 
                data.description.map ((item, index) =>( 
                  
                  <div className="flex md:px-[4rem] py-[10px] sm:px-[10px] sm:py-[5px] text-[13px] font-semibold text-black">
                    <div className="md:w-[50vw] sm:w-[40vw] text-left "> {(item.service_name[i18n.language] !== '' ? item.service_name[i18n.language] : item.service_name['tj'])} </div>
                    <div className="w-[10vw] text-center"> {item.discount_name ? item.discount_name : item.service_unit_qty} </div>
                    {/*<div className="w-[10vw] text-center"> {item.service_unit} </div>*/}
                    <div className="md:w-[15vw] sm:w-[20vw] text-right"> {numberWithSpaces((item.service_price/100).toFixed(2))} </div>
                    <div className="md:w-[15vw] sm:w-[20vw] text-right"> {numberWithSpaces((item.service_unit_qty * item.service_price/100).toFixed(2))} </div>
                  </div>   
                )) 
              } 
            </div>
            <div className="h-[1px] bg-black sm:mx-[10px] md:mx-[3.5rem] " />
            <div id = "InvoiceSummary" className="flex bg-slate-100 md:px-[4rem] sm:px-[10px] py-[2px] text-[15px] font-semibold text-black">
              <div className="w-full text-center"> 
                {translation('TOTAL_AMOUNT')} {numberWithSpaces((data.amount_due/100).toFixed(2))} {translation('CURRENCY_1')}
              </div>
              
            </div>
        

              
            

            <div className="h-[2px] bg-black" />
            
            {data.status === 'open' ?(
              <>
              {data.amount_paid !== 0 ? <>
              <div id = "InvoiceSummary" className="flex bg-slate-50 md:px-[4rem] sm:px-[10px] py-[2px] text-[13px] font-semibold text-red-600">
                <div className="w-full text-center"> 
                  {translation('UNPAID')} {numberWithSpaces((data.amount_to_pay/100).toFixed(2))} {translation('CURRENCY_1')}
                </div>
              
              </div>
              </>:<></>
            }
            {/*<div id = "InvoiceFooter" className="flex p-[10px] text-justify text-slate-500 rounded-b-[10px] text-[14px]">
                {translation('INVOICE_FOOTER')}
            </div>
            <div id = "InvoicePayment" className="sm:block md:flex bg-white text-center sm:justify-center items-center w-full md:px-[4rem] sm:p-[1rem]">
              <div className="flex justify-center pb-4">
                <a className="sm:w-[300px] flex justify-center items-center text-center text-white rounded-full md:text-[18px] sm:text-[14px] py-2 px-8  bg-indigo-700 hover:bg-indigo-500" 
                  onClick={() => this.startPayment()}> 
                  <div >{translation('PAY_WITH')}</div>
                  <img className='pl-4 h-[28px]' src='/assets/img/korty_milly2.png'></img>
                  <img className='pl-4 h-[16px]' src='/assets/img/visa_white.png'></img>
                </a>
              </div>
              </div>
              <div className="h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />
              <div id = "InvoiceFooter" className="flex p-[10px] text-justify text-sky-600 rounded-b-[10px] text-[14px]">
                {translation('TERMINAL_1')} - {numberWithSpaces(data.invoice_number)} {translation('TERMINAL_2')}
              </div>
              
            <div className="h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />*/}
              </>
              ):( 
                data.status === 'void' ?(
                  <>
                    <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                      <div className="void_stamp"> {translation('VOID_STAMP')}</div>
                    </div>
                    <div className="h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />
                  </>

              ):(

                <>
                  <div className="flex justify-center items-center bg-white p-[20px]" style={{background:"#fff", padding:"20px"}}>
                  {data.amount_due === 0 ? (
                      <div className="void_stamp text-center"> ТАХФИФ 100% </div>
                    ):(
                      <div className="stamp_m text-center"> {translation('PAYED_STAMP')}</div>
                    )
                  }
                  </div>
                  <div className="h-[2px] bg-gradient-to-r from-[#397f90] to-transparent" />
                </>
              )

              )

            }
            
            
          </div>
          }
          <div className="md:h-40"></div>
      </div>
      
      

      
    
      </div>
      );

  } 
  
}


export default withRouter(ReceiptForm) ;